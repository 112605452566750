<template>
    <q-list class="text-grey-3 q-pt-sm">
        <template v-for="(menu, index) in admin_menu" :key="index">
            <template v-if="is_view(menu)">
            <q-expansion-item group="menu" class="left_menu pb2" expand-separator :class="{active:menu.is_selected}"
                            :icon="menu.icon" :label="menu.title" v-model="menu.is_selected"
                            @click="goto_link(menu)" >
                <q-item clickable v-ripple @click="goto_link(item)" 
                        v-for="(item, i) in menu.children" :key="index+'.'+i">
                    <q-item-section avatar></q-item-section>
                    <q-item-section> {{ item.title }} </q-item-section>
                    <template v-if="item.children.length>0">
                        <q-item-section side >
                            <q-icon name="keyboard_arrow_right" ></q-icon>
                        </q-item-section>
                        <admin-left-pop-menu :menu="item" :key-index="''+i" ></admin-left-pop-menu>
                    </template>
                </q-item>
            </q-expansion-item>
            </template>
        </template>
        <template v-for="(menu, index) in system_menu" :key="index">
            <template v-if="is_view(menu)">
            <q-expansion-item group="menu" class="left_menu pb2" expand-separator :class="{active:menu.is_selected}"
                            :icon="menu.icon" :label="menu.title" v-model="menu.is_selected"
                            @click="goto_link(menu)" >
                <q-item clickable v-ripple @click="goto_link(item)" 
                        v-for="(item, i) in menu.children" :key="index+'.'+i">
                    <q-item-section avatar></q-item-section>
                    <q-item-section> {{ item.title }} </q-item-section>
                    <template v-if="item.children.length>0">
                        <q-item-section side >
                            <q-icon name="keyboard_arrow_right" ></q-icon>
                        </q-item-section>
                        <admin-left-pop-menu :menu="item" :key-index="''+i" ></admin-left-pop-menu>
                    </template>
                </q-item>
            </q-expansion-item>
            </template>
        </template>
    </q-list>
</template>

<script>
import adminLeftPopMenu from "./adminLeftPopMenu.vue"

export default {
    components : {
        adminLeftPopMenu,
    },
    data() {
        return {
            admin_menu : [
                    { title : '홈화면', icon : 'home', is_selected: false,
                        route:"staff/home",
                        link : 'staff/home', children: [] },
                    { title : '반관리', icon : 'o_location_city', is_selected: false,
                        route:"staff/class",
                        link : '', children: [
                        { title : '반관리', link : 'staff/class/manage', children: [] },
                        { title : '일괄 전/퇴반', link : 'staff/class/batch_job', children: [] },
                        ] },
                    { title : '수업관리', icon : 'account_circle', is_selected: false,
                        route:"teacher",
                        link : '', children: [
                        { title : '오늘수업', link : 'teacher/class/today', children: [] },
                        ] },
                    { title : '메시지관리', icon : 'message', is_selected: false,
                        route:"staff/message",
                        link : '', children: [
                        { title : '메시지전송', link : 'staff/message/send', children: [] },
                        { title : '전송결과', link : 'staff/message/result', children: [] },
                        ] },
                    { title : '업무프로세스', icon : 'analytics', is_selected: false,
                        route:"process",
                        link : '', children: [
                        { title : '입학관리', link : 'process/enter', children: [] },
                        { title : '수업미션관리', link : 'process/work', children: [] },
                        ] },
            ],
            system_menu : [
                    { title : '학원관리', icon : 'analytics', is_selected: false,
                        route:"stat",
                        link : '', children: [
                        { title : '학생관리', link : 'stat/student', children: [] },
                        //{ title : '강사관리', link : 'stat/teacher', children: [] },
                        { title : '수납관리', link : 'stat/payment', children: [] },
                        { title : '위험도분석', link : 'stat/drop_rate', children: [] },
                        ] },
                    { title : '설정', icon : 'settings', is_selected: false,
                        route:"setting",
                        link : '', children: [
                        { title : '기초데이터', link : 'setting/init', children: [] },
                        { title : '학원정보', link : 'setting/center', children: [] },
                        { title : '직원관리', link : 'setting/teacher', children: [] },
                        { title : '권한설정', link : 'setting/permission', children: [] },
                        { title : '등하원Qr', link : 'setting/timeqr', children: [] },
                        { title : '등하원(학생)', link : 'setting/time_student', children: [] },
                        ] },
            ],
        }
    },
    computed : {
        admin() {
            return this.$store.state.admin;
        },
        permission() {
            return this.$store.state.permission;
        },
    },
    methods : {
        is_view(menu) {
            let key = menu.route;
            if( this.$store.state.admin.admin_group_id==1) { return true; }
            if(this.$c.hasProperty(this.permission, key)===true) {
                return this.permission[key].view==1;
            }
            return false;
        },

        link_prefix() {
            return '/admin/app/aca_erp/';
        },
        leftMenu_click : function(route) {
            var i;
            for(i=0; i<this.admin_menu.length; i++) {
                let menu_route = this.link_prefix() + this.admin_menu[i].route;
                if( route.startsWith(menu_route)) {
                    this.admin_menu[i].is_selected= !this.admin_menu[i].is_selected;
                } else {
                    this.admin_menu[i].is_selected = false;
                }
            }
            for(i=0; i<this.system_menu.length; i++) {
                let menu_route = this.link_prefix()  + this.system_menu[i].route;
                if( route.startsWith(menu_route)) {
                    this.system_menu[i].is_selected= !this.system_menu[i].is_selected;
                } else {
                    this.system_menu[i].is_selected = false;
                }
            }
        },
        goto_link : function(menu) {
            let menu_link = this.link_prefix() + menu.link;
            if(menu.children.length==0 && menu.link) {
                location.href = menu_link;
            }
        },
    },
    
    beforeMount() {
        this.leftMenu_click(window.location.pathname);
    },
}
</script>

<style>
.q-item__section--avatar {
    min-width: 40px;
}
.q-expansion-item.active {
    background-color:#333;
}
.q-expansion-item.left_menu > .q-expansion-item__container > .q-item {
    min-height:36px;
}
</style>
